<template>
    <div class="text-center">
        <!-- <a @click="dialog = true" title="Hapus" v-if="shape == 'icon'">
            <v-icon :title="'Hapus' + kind" class="">mdi-delete</v-icon>
        </a> -->

          <b-button  @click="dialog = true" title="Hapus" v-if="shape == 'icon'" variant="outline-secondary" size="sm"
            class="" color="btn float-left" small>Hapus
        </b-button>
        
        <v-btn color="primary" @click="dialog = true" v-else>
            {{ name }}
        </v-btn>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <v-toolbar dark color="deep-purple" dense flat>
                    <v-toolbar-title class="text-body-2 font-weight-bold">
                        Hapus {{ kind }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pa-4 black--text">
                    Anda yakin akan menghapus {{ kind }} ?
                </v-card-text>
                <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn color="grey" class="body-2 font-weight-bold" @click.native="dialog = false" text>Batal</v-btn>
                    <v-btn color="error" class="body-2 font-weight-bold" @click.native="deleteAction" outlined>Hapus</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

export default {
    props: ['name', 'title', 'kind', 'shape'],

    components: {},

    data: function() {
        return {
            dialog: false,
        }
    },

    methods: {
        deleteAction: function() {
            this.$emit('delete')
            this.dialog = false
        },
    },

    watch: {},
}

</script>

