<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">

                    <div class="chat-right-aside" style="width:100%">
                        <div class="chat-main-header">
                        </div>
                        <div class="chat-rbox static-content">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <div class="contact-add">
                                        <form class="form-horizontal form-material">
                                            Daftar Broadcast
                                            <div class="clearfix"></div>
                                            <div class="wrapp-input-contact m-t-20">
                                                <div class="list-auto-template" v-if="!showAddAutoTemplate">
                                                    <v-row>
                                                        <v-col cols="3">
                                                            <b-form-input v-model="text" placeholder="Cari kode/nama">
                                                            </b-form-input>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <b-button variant="primary">Cari</b-button>
                                                        </v-col>
                                                    </v-row>

                                                    <div class="my-5">
                                                        <table class="table table-bordered text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" scope="col">
                                                                        Kode
                                                                    </th>
                                                                    <th rowspan="2" scope="col">
                                                                        Nama
                                                                    </th>

                                                                    <th colspan="5">
                                                                        Jumlah
                                                                    </th>


                                                                </tr>
                                                                <tr>
                                                                    <th>Grup</th>
                                                                    <th>Kontak</th>
                                                                    <th>Pesan Terkirim</th>
                                                                    <th>Pesan Terbaca</th>
                                                                    <th>Pesan Tidak Terbaca</th>
                                                                </tr>

                                                                <tr>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">Lorem Ipsum</th>
                                                                    <td>20</td>
                                                                    <td>10</td>
                                                                    <td>10</td>
                                                                    <td>10</td>
                                                                    <td>10</td>
                                                                    <td>10</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div class="add-auto-template" v-if="showAddAutoTemplate">
                                                    Auto Template
                                                    <v-switch class="pull-right m-t-0" label="Aktif"></v-switch>
                                                    <div class="clearfix"></div>
                                                    <br>

                                                    <div class="row">
                                                        <label for="f-password"
                                                            class="col-sm-12 col-lg-3 text-right control-label col-form-label">
                                                            Kategori</label>
                                                        <div class="col-sm-12 col-lg-6 m-t-0 p-t-0">
                                                            <input type="text" class="form-control"
                                                                id="f-user-full-name" placeholder="">
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <label for="f-user-full-name"
                                                            class="col-sm-12 col-lg-3 text-right control-label col-form-label">
                                                            Judul</label>
                                                        <div class="col-sm-12 col-lg-6 m-t-0 p-t-0">
                                                            <input type="text" class="form-control"
                                                                id="f-user-full-name" placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="f-user-full-name"
                                                            class="col-sm-12 col-lg-3 text-right control-label col-form-label">
                                                            Deskripsi</label>
                                                        <div class="col-sm-12 col-lg-8 ">
                                                            <textarea class="form-control"
                                                                id="exampleFormControlTextarea1" rows="3"></textarea>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="row">
                                                        <label for="f-user-full-name"
                                                            class="col-sm-12 col-lg-3 text-right control-label col-form-label">
                                                        </label>
                                                        <div class="col-sm-12 col-lg-8">
                                                            <a @click="showAddAutoTemplate = false"
                                                                class="btn btn-primary m-r-10">
                                                                Simpan
                                                            </a>

                                                            <a @click="showAddAutoTemplate = false"
                                                                class="btn btn-light">
                                                                Batal
                                                            </a>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SuccessNotif />
    </div>
</template>
<script>
    // import axios from 'axios'
    // import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import {
        mapState
    } from 'vuex'

    // const $ = require("jquery")
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')
    var moment = require('moment');

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },
        data: function () {
            return {
                selectedStatus: String(),
                status: ['Draft', 'Diproses', 'Selesai', 'Dibatalkan'],
                isOpenDate: false,
                selectedDate: String(),
                date: new Date().toISOString().substr(0, 10),
                sendDate: new Date(),
                headers: [{
                        text: 'Kode',
                        value: '_id'
                    },
                    {
                        text: 'Nama',
                        value: 'name'
                    },
                    {
                        text: 'Status Broadcast',
                        value: 'action'
                    },
                    {
                        text: 'Tanggal Kirim',
                        value: 'name'
                    },
                    {
                        text: 'Deskripsi',
                        value: 'action'
                    },
                ],
                broadcastList: Array(),
                totalElements: 0,
                options: Object(),
                isLoading: false,
            }
        },
        watch: {
            $route: async function (to, from) {
                if (this.$helpers.isBlank(to.params.id)) {
                    this.resetToEmptyForm();
                } else if (to.params.id != from.params.id) {
                    this.selectCommChannel(decodeURIComponent(to.params.id));
                }
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
        },
        methods: {
            loadInitialData: async function () {
                await this.loadCommChannel();
                if (this.$route.params.id != null) {
                    this.selectCommChannel(decodeURIComponent(this.$route.params.id));
                }
            },
            resetToEmptyForm: function () {
                this.commChannelList.forEach(element => {
                    element.selected = false
                })
                this.commChannel = {
                    ...this.commChannel,
                    mode: '',
                    _id: '',
                    name: '',
                    status: 'INACTIVE',
                    webhook: '',
                    channelType: '',
                    waboxToken: '',
                    waboxPhoneNumber: '',
                    waboxDefaultCountryCode: '',
                    fbmsgrVerifyToken: '',
                    fbmsgrPageId: '',
                    fbmsgrPageAccessToken: '',
                    fbmsgrAppSecret: '',
                    fbmsgrAppId: ''
                }
            },
            utcToLocal: function (date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },
            utcToLocalView: function (date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },
            localToUtc: function (date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: function () {
            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
            }
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }
        },
        components: {
            // ErrorMessage,
            SuccessNotif
        }
    }
</script>