<template>
    <v-row justify="space-around">
        <v-col cols="auto">
            <v-dialog v-model="show" 
                persistent
                @keydown.esc="closeDialog"
                transition="dialog-bottom-transition" max-width="600"
            >
                <template>
                    <v-card>
                        <v-toolbar color="deep-purple accent-4" class="text-h6 text-white" style="background-color:red">
                            <span class="text-white">Session</span>
                        </v-toolbar>
                        <!-- <div class="text-center mt-5">
                            <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                            ></v-progress-circular>
                        </div> -->
                        <v-form>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        Status: {{ sessionDetail.connectionState }}
                                    </v-col>
                                </v-row>
                                <v-row v-if="sessionDetail.connectionState == 'waitingqrscanned' || sessionDetail.connectionState == 'connecting'">
                                    <v-col>
                                        <canvas id="qrImage" width="300" height="300"></canvas>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn @click="closeDialog">
                                    Kembali
                                </v-btn>
                                <v-btn class="ml-2 btn btn-primary" v-if="sessionDetail.connectionState == 'connected'"
                                    style="color: white;"
                                    :loading="isLoading"
                                    :disabled="isLoading"
                                    @click="logoutSession">
                                    Logout
                                </v-btn>
                                <v-btn class="ml-2 btn btn-primary" v-if="sessionDetail.connectionState == 'waitingqrscanned' || sessionDetail.connectionState == 'connecting'"
                                    style="color: white;"
                                    :loading="isLoading"
                                    :disabled="isLoading"
                                    @click="logoutSession">
                                    disconnect
                                </v-btn>
                                <v-btn class="ml-2 btn btn-primary" v-if="sessionDetail.connectionState != 'connected'"
                                    style="color: white;"
                                    :loading="sessionDetail.connectionState == 'waitingqrscanned' || sessionDetail.connectionState == 'connecting' || isLoading"
                                    :disabled="sessionDetail.connectionState == 'waitingqrscanned' || sessionDetail.connectionState == 'connecting' || isLoading"
                                    @click="createSession">
                                    Login
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </template>
            </v-dialog>
        </v-col>
    </v-row>
</template>
<script>
import axios from 'axios'

import QRCode from 'qrcode'

export default {
    props: ['show', 'commChannel'],

    components: {},

    data: function() {
        return {
            isLoading: false,
            isConnected: false,

            sessionDetail: {
                connectionState: String(),
                hookUrl: String(),
                qr: String()
            }
        }
    },

    methods: {
        generateQR: async function (text) {
            try {
                return await QRCode.toDataURL(text)
            } catch (err) {
                console.error(err)
            }
        },

        getSession: async function () {
            try {
                const { data } = await axios.get(process.env.VUE_APP_API_URL + `/baileys/session/${this.commChannel._id}`)

                this.sessionDetail = data
                
                if(data.connectionState == 'waitingqrscanned' || data.connectionState == 'connecting') {
                    const qrString = await this.generateQR(data.qr)
                    
                    var canvas = document.getElementById("qrImage");
                    var ctx = canvas.getContext("2d");
    
                    var image = new Image();
    
                    image.onload = function() {
                        ctx.drawImage(image, 0, 0);
                    };
    
                    image.src = qrString
                }

            } catch (err) {
                this.isLoading = false
                console.error(err)
            }
        },

        createSession: async function () {
            try {
                this.isLoading = true

                const body = {
                    sessionId: this.commChannel._id,
                    hookUrl: this.commChannel.webhook,
                    token: this.commChannel.waboxToken,
                }

                const { data } = await axios.post(process.env.VUE_APP_API_URL + '/baileys/session', body)

                this.sessionDetail = {
                    ...this.sessionDetail,
                    connectionState: data.connState,
                    qr: data.qr
                }

                const qrString = await this.generateQR(data.qr)

                var canvas = document.getElementById("qrImage");
                var ctx = canvas.getContext("2d");

                var image = new Image();

                image.onload = function() {
                    ctx.drawImage(image, 0, 0);
                };

                image.src = qrString
                
                await this.loopLoadDetail()

                this.isLoading = false
            } catch (err) {
                console.error(err)
            }
        },

        loopLoadDetail: async function() {
            var e = this

            await e.getSession()

            var intervalId = setInterval(async function() {
                if(e.sessionDetail.connectionState != 'waitingqrscanned' && e.sessionDetail.connectionState != 'connecting') {
                    clearInterval(intervalId);
                } else {
                    await e.getSession()
                }
            }, 10000);
        },

        logoutSession: async function () {
            try {
                this.isLoading = true

                const body = {
                    sessionId: this.commChannel._id,
                    hookUrl: this.commChannel.webhook,
                }

                await axios.put(process.env.VUE_APP_API_URL + '/baileys/session/logout', body)

                await this.getSession()

                this.isLoading = false
            } catch (err) {
                console.error(err)
            }
        },

        closeDialog: function() {
            this.$emit('close-dialog', false)

            this.sessionDetail = {
                connectionState: String(),
                hookUrl: String(),
                qr: String()
            }
        },
    },

    watch: {
        show: async function(val) {
            if(val) {
                this.isLoading = true
                await this.loopLoadDetail()
                this.isLoading = false
            } else {
                this.sessionDetail = Object()
            }
        }
    },
}

</script>
