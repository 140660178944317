<template>
    <div>
        <Pie
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        />
    </div>
</template>
<script>
    import { Pie } from 'vue-chartjs/legacy'
    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        ArcElement,
        CategoryScale
    } from 'chart.js'

    import { mapState } from 'vuex'

    ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                broadcastCallback: state => state.socket.broadcastCallback,
            })
        },
        data: function() {
            return {
                chartData: {
                    labels: ['Terkirim', 'Tidak Terkirim', 'Menunggu Dikirim', 'Sedang Dikirim', 'Dibaca', 'Sampai Ke Tujuan', 'Berhenti Berlangganan'],
                    datasets: [{
                        backgroundColor: ['#ffc107', '#ced4da', '#0d6efd', '#198754', '#0dcaf0', '#dc3545', '#eda247'],
                        data: [0, 0, 0, 0, 0, 0]
                    }]
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                },
            }
        },
        methods: {
            changeLabelColor(labels) {
                return labels.map(e => {
                    let key = ""
    
                    switch(e) {
                        case "delivered": 
                            key = '#198754';
                            break;
                        case "error": 
                            key = '#dc3545';
                            break;
                        case "pending": 
                            key = '#ced4da';
                            break;
                        case "queued": 
                            key = '#ffc107';
                            break;
                        case "read": 
                            key = '#0dcaf0';
                            break;
                        case "sent": 
                            key = '#0d6efd';
                            break;
                        case "unsubscribed":
                            key = '#eda247'
                            break;
                    }
    
                    return key
                })
            },
            changeLabelName(labels) {
                return labels.map(e => {
                    let key = ""
    
                    switch(e) {
                        case "delivered": 
                            key = 'Terkirim';
                            break;
                        case "error": 
                            key = 'Tidak Terkirim';
                            break;
                        case "pending": 
                            key = 'Menunggu Dikirim';
                            break;
                        case "queued": 
                            key = 'Sedang Dikirim';
                            break;
                        case "read": 
                            key = 'Dibaca';
                            break;
                        case "sent": 
                            key = 'Sampai Ke Tujuan';
                            break;
                        case "unsubscribed":
                            key = 'Berhenti Berlangganan'
                            break;
                    }
    
                    return key
                })
            },
        },
        watch: {
            broadcastCallback: function(val) {
                const deliverySummary = {
                    "queued": 0,
                    "pending": 0,
                    "error": 0,
                    "sent": 0,
                    "delivered": 0,
                    "read": 0,
                    "unsubscribed": 0, 
                    ...val.deliverySummary
                }

                if(this.broadcastId == val.id) {
                    if(Object.keys(val.deliverySummary).length != 0) {
                        this.chartData.datasets[0].backgroundColor = this.changeLabelColor(Object.keys(deliverySummary))
                        this.chartData.labels = this.changeLabelName(Object.keys(deliverySummary))
                        this.chartData.datasets[0].data = Object.values(deliverySummary)
                    }
                }
            },
        },
        mounted() {
            if(Object.keys(this.chartValue).length != 0) {
                const deliverySummary = {
                    "queued": 0,
                    "pending": 0,
                    "error": 0,
                    "sent": 0,
                    "delivered": 0,
                    "read": 0,
                    "unsubscribed": 0, 
                    ...this.chartValue
                }
                this.chartData.labels = this.changeLabelName(Object.keys(deliverySummary))
                this.chartData.datasets[0].backgroundColor = this.changeLabelColor(Object.keys(deliverySummary))
                this.chartData.datasets[0].data = Object.values(deliverySummary)
            }
        },
        props: {
            chartId: {
                type: String,
                default: 'pie-chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 400
            },
            height: {
                type: Number,
                default: 400
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => {}
            },
            plugins: {
                type: Array,
                default: () => []
            },
            chartValue: {
                type: Object,
                default: () => {}
            },
            broadcastId: {
                type: String,
                default: '',
            }
        },
        components: {
            Pie,
        },
    }
</script>