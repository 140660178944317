<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <!-- .chat-row -->
                <div class="chat-main-box">

                    <div class="chat-right-aside" style="width:100%">
                        <div class="chat-main-header">
                        </div>
                        <div class="chat-rbox static-content">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <div class="contact-add">
                                        <form class="form-horizontal form-material">
                                            Daftar Kategori 
                                            <a @click="showAddAutoTemplate = true" class="btn btn-primary pull-right">
                                                Tambah Kategori
                                            </a>
                                            <div class="clearfix"></div>
                                            <div class="wrapp-input-contact m-t-20">
                                                <div class="list-auto-template" v-if="!showAddAutoTemplate">
                                                    <div class="row ">
                                                        <div class="col-sm-12 col-lg-4">
                                                            <input type="text" class="form-control "
                                                                placeholder="Cari Judul">
                                                        </div>

                                                        <div class="col-sm-12 col-lg-2">
                                                            <a href="" class="btn btn-primary">
                                                                Cari
                                                            </a>
                                                        </div>

                                                    </div>

                                                    <div class="table-responsive">

                                                    <table class="table table-striped m-t-20 table-bordered text-center">
                                                        <thead>
                                                            <tr>
                                                                <th class="font-weight-bold" scope="col">Waktu
                                                                    Modifikasi</th>
                                                                <th class="font-weight-bold" scope="col">Kategori</th>
                                                                <th class="font-weight-bold" scope="col">Status</th>
                                                                <th class="font-weight-bold" scope="col">Deskripsi</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>06/05/2021</td>
                                                                <td>Shopping</td>
                                                                <td>aktif</td>
                                                                <td>Pesanan nomor 10000100 dengan total pembelanjaan RP
                                                                    10.000.000, untuk tujuan pengiriman jalan pasir
                                                                    honje no
                                                                    348, cimeunyan, bandung, jawa barat, 40191
                                                                    menggunakan
                                                                    kurir JNE Oke, dengan berat 2,5 kg dan ongkos kiri
                                                                    yang
                                                                    harus dibayarkan sebersar Rp 15.000.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>05/05/2021</td>
                                                                <td>Retur</td>
                                                                <td>inaktif</td>
                                                                <td>Status pengembaian produk AT-011 pada nota 1000010,
                                                                    ‘berhasil/tidak berhasil’. Terima kasih banyak telah
                                                                    membeli produk tuneeca.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>

                                                <div class="add-auto-template" v-if="showAddAutoTemplate">
                                                    Auto Template
                                                    <v-switch class="pull-right m-t-0" label="Aktif"></v-switch>
                                                    <div class="clearfix"></div>
                                                    <br>

                                                    <div class="row">
                                                        <label for="f-password"
                                                            class="col-sm-12 col-lg-3 text-right control-label col-form-label">
                                                            Kategori</label>
                                                        <div class="col-sm-12 col-lg-6 m-t-0 p-t-0">
                                                            <input type="text" class="form-control"
                                                                id="f-user-full-name" placeholder="">
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <label for="f-user-full-name"
                                                            class="col-sm-12 col-lg-3 text-right control-label col-form-label">
                                                            Judul</label>
                                                        <div class="col-sm-12 col-lg-6 m-t-0 p-t-0">
                                                            <input type="text" class="form-control"
                                                                id="f-user-full-name" placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="f-user-full-name"
                                                            class="col-sm-12 col-lg-3 text-right control-label col-form-label">
                                                            Deskripsi</label>
                                                        <div class="col-sm-12 col-lg-8 ">
                                                            <textarea class="form-control"
                                                                id="exampleFormControlTextarea1" rows="3"></textarea>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="row">
                                                        <label for="f-user-full-name"
                                                            class="col-sm-12 col-lg-3 text-right control-label col-form-label">
                                                        </label>
                                                        <div class="col-sm-12 col-lg-8">
                                                            <a @click="showAddAutoTemplate = false"
                                                                class="btn btn-primary m-r-10">
                                                                Simpan
                                                            </a>

                                                            <a @click="showAddAutoTemplate = false"
                                                                class="btn btn-light">
                                                                Batal
                                                            </a>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SuccessNotif />
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import {
        mapState
    } from 'vuex'
    import countryCodes from "../utils/country-codes"

    const $ = require("jquery")
    const debounce = require('lodash.debounce')
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')

    const USER_ROW_PER_PAGE = 25
    var userScrollBar = null

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo
            })
        },
        data: function () {
            return {
                showListAutoTemplate: false,
                showAddAutoTemplate: false,
                items: ['Kategori 1', 'Kategori 2', 'Kategori 3', 'Kategori 4'],
                countryCodes: countryCodes,
                availableChannels: [{
                    id: "WABOXAPP",
                    name: "WA via WABOXAPP"
                }, {
                    id: "FBMSGR",
                    name: "Facebook Messenger"
                }, {
                    id: "SMS",
                    name: "SMS"
                }],
                commChannelList: [],
                commChannelUIFlag: {
                    error: {},
                    currentPage: 1,
                    searchTerm: '',
                    selectedUser: '',
                    hasMoreData: true,
                    isLoading: false
                },
                commChannel: {
                    mode: '',
                    _id: '',
                    name: '',
                    status: 'INACTIVE',
                    webhook: '',
                    channelType: '',
                    waboxToken: '',
                    waboxPhoneNumber: '',
                    waboxDefaultCountryCode: '',
                    fbmsgrVerifyToken: '',
                    fbmsgrPageId: '',
                    fbmsgrPageAccessToken: '',
                    fbmsgrAppSecret: '',
                    fbmsgrAppId: ''
                },
                commChannelDetailFlag: {
                    error: {},
                    isLoading: false,
                    isSaving: false
                },
                isTyping: false
            }
        },
        watch: {
            'commChannelUIFlag.searchTerm': debounce(function () {
                this.isTyping = false;
            }, 1000),
            $route: async function (to, from) {
                if (this.$helpers.isBlank(to.params.id)) {
                    this.resetToEmptyForm();
                } else if (to.params.id != from.params.id) {
                    this.selectCommChannel(decodeURIComponent(to.params.id));
                }
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
            isTyping: function (value) {
                if (!value) {
                    this.loadCommChannel();
                }
            }
        },
        methods: {
            selectCommChannel: async function (commChannelId) {
                this.commChannelList.forEach(element => {
                    if (element._id == commChannelId) {
                        element.selected = true
                    } else {
                        element.selected = false
                    }
                })
                this.commChannelDetailFlag.error = {}
                this.commChannelDetailFlag.isLoading = true;
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/commchannel/' + commChannelId);
                    var jsonData = resp.data;
                    this.commChannel = {
                        ...this.commChannel,
                        mode: 'edit',
                        _id: jsonData._id,
                        name: jsonData.name,
                        status: jsonData.status,
                        webhook: jsonData.webhook,
                        channelType: jsonData.channel_type,
                        waboxToken: jsonData.wabox_token,
                        waboxPhoneNumber: jsonData.wabox_phone_number,
                        waboxDefaultCountryCode: jsonData.wabox_default_country_code,
                        fbmsgrVerifyToken: jsonData.fbmsgr_verify_token,
                        fbmsgrPageId: jsonData.fbmsgr_page_id,
                        fbmsgrPageAccessToken: jsonData.fbmsgr_page_access_token,
                        fbmsgrAppSecret: jsonData.fbmsgr_app_secret,
                        fbmsgrAppId: jsonData.fbmsgr_app_id,
                    }
                } catch (error) {
                    this.commChannelDetailFlag.error = this.$helpers.createError(error)
                }
                this.commChannelDetailFlag.isLoading = false
            },
            loadCommChannel: async function () {
                this.commChannelList = []
                this.commChannelUIFlag.currentPage = 1
                await this.loadUserOnPage()
            },
            loadMoreUsers: async function () {
                this.commChannelUIFlag.currentPage++
                this.loadUserOnPage()
            },
            loadUserOnPage: async function () {
                this.commChannelUIFlag.error = {}
                this.commChannelUIFlag.hasMoreData = true;
                this.commChannelUIFlag.isLoading = true;

                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/commchannel/', {
                        params: {
                            page: this.commChannelUIFlag.currentPage,
                            sort: this.commChannelUIFlag.sortOrder,
                            term: this.commChannelUIFlag.searchTerm,
                            size: USER_ROW_PER_PAGE
                        }
                    });
                    this.commChannelUIFlag.isLoading = false
                    var jsonData = resp.data
                    this.commChannelUIFlag.hasMoreData = jsonData.page.number < jsonData.page.totalPages;
                    this.commChannelList.splice(this.commChannelList.length, 0, ...jsonData.content);
                } catch (error) {
                    this.commChannelList.error = this.$helpers.createError(error)
                }
                this.commChannelUIFlag.isLoading = false
            },
            loadInitialData: async function () {
                await this.loadCommChannel();
                if (this.$route.params.id != null) {
                    this.selectCommChannel(decodeURIComponent(this.$route.params.id));
                }
            },
            saveChannel: async function (event) {
                event.preventDefault()
                var errors = []
                if (this.$helpers.isBlank(this.commChannel.name)) {
                    errors.push("'Nama' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.channelType)) {
                    errors.push("'Jenis Kanal' harus dipilih")
                }
                if (this.$helpers.isBlank(this.commChannel.waboxToken) && this.commChannel.channelType ==
                    'WABOXAPP') {
                    errors.push("'Token' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.waboxPhoneNumber) && this.commChannel.channelType ==
                    'WABOXAPP') {
                    errors.push("'No Telepon' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.fbmsgrPageId) && this.commChannel.channelType ==
                    'FBMSGR') {
                    errors.push("'Page ID' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.fbmsgrPageAccessToken) && this.commChannel.channelType ==
                    'FBMSGR') {
                    errors.push("'Page Access Token' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.fbmsgrAppSecret) && this.commChannel.channelType ==
                    'FBMSGR') {
                    errors.push("'Application Secret' tidak boleh kosong")
                }
                if (this.$helpers.isBlank(this.commChannel.fbmsgrAppId) && this.commChannel.channelType ==
                    'FBMSGR') {
                    errors.push("'Application ID' tidak boleh kosong")
                }

                if (errors.length > 0) {
                    this.commChannelDetailFlag.error = this.$helpers.createErrorFromList(errors)
                } else {
                    var data = {
                        name: this.commChannel.name,
                        status: this.commChannel.status,
                        channel_type: this.commChannel.channelType
                    }

                    if (this.commChannel.channelType == 'WABOXAPP') {
                        data.wabox_token = this.commChannel.waboxToken
                        data.wabox_phone_number = this.commChannel.waboxPhoneNumber
                        data.wabox_default_country_code = this.commChannel.waboxDefaultCountryCode
                    } else if (this.commChannel.channelType == 'FBMSGR') {
                        data.fbmsgr_page_id = this.commChannel.fbmsgrPageId
                        data.fbmsgr_page_access_token = this.commChannel.fbmsgrPageAccessToken
                        data.fbmsgr_app_secret = this.commChannel.fbmsgrAppSecret
                        data.fbmsgr_app_id = this.commChannel.fbmsgrAppId
                    }

                    this.commChannelDetailFlag.isSaving = true
                    try {
                        var resp
                        if (this.commChannel.mode == 'edit') {
                            resp = await axios.put(process.env.VUE_APP_API_URL +
                                `/commchannel/${this.commChannel._id}`,
                                data)
                            var foundIdx = this.commChannelList.findIndex(el => el._id == this.commChannel._id)
                            if (foundIdx < 0) {
                                this.commChannelList.splice(0, 0, resp.data)
                            } else {
                                this.commChannelList.splice(foundIdx, 1, resp.data)
                            }
                            this.selectCommChannel(this.commChannel._id)
                        } else {
                            data._id = this.commChannel.generatedId
                            resp = await axios.post(process.env.VUE_APP_API_URL + '/commchannel/', data)
                            this.commChannelList.splice(0, 0, resp.data)
                            this.selectCommChannel(resp.data._id)
                        }

                        this.$bvToast.show('success-notif')
                    } catch (error) {
                        this.commChannelDetailFlag.error = this.$helpers.createError(error)
                    }
                    this.commChannelDetailFlag.isSaving = false
                }
            },
            deleteChannel: async function () {
                var modalResult = await this.$bvModal.msgBoxConfirm(`Hapus data kanal '${this.commChannel.name}'?`)
                if (modalResult) {
                    var id = this.commChannel._id
                    await axios.delete(process.env.VUE_APP_API_URL + `/commchannel/${id}`)
                    var foundIdx = this.commChannelList.findIndex(el => el._id == id)
                    if (foundIdx >= 0) {
                        this.commChannelList.splice(foundIdx, 1)
                    }
                    this.$router.push('/commchannel/')
                }
            },
            resetToEmptyForm: function () {
                this.commChannelList.forEach(element => {
                    element.selected = false
                })
                this.commChannel = {
                    ...this.commChannel,
                    mode: '',
                    _id: '',
                    name: '',
                    status: 'INACTIVE',
                    webhook: '',
                    channelType: '',
                    waboxToken: '',
                    waboxPhoneNumber: '',
                    waboxDefaultCountryCode: '',
                    fbmsgrVerifyToken: '',
                    fbmsgrPageId: '',
                    fbmsgrPageAccessToken: '',
                    fbmsgrAppSecret: '',
                    fbmsgrAppId: ''
                }
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: function () {
            Scrollbar.initAll();
            userScrollBar = Scrollbar.get($('#left-inner-scrollbar').get()[0]);

            var self = this;
            userScrollBar.addListener((status) => {
                if (this.commChannelUIFlag.hasMoreData && status.offset.y == userScrollBar.limit.y) {
                    setTimeout(() => self.loadMoreUsers(), 500);
                }
            });
            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
            }
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }
        },
        components: {
            // ErrorMessage,
            SuccessNotif
        }
    }
</script>