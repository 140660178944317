<template>
    <div class="add-auto-template" v-if="showPage == 'form'">
        <div class="font-weight-bold">
            {{ mode == 'edit' ? 'Edit' : 'Buat' }} Template
            <span class="bg-success text-white px-2" v-if="form.cloud">
                {{ form.cloud.status }}
            </span> 
            <v-btn class="pull-right btn btn-primary" @click="showDialog = true">Submit To Business</v-btn>
            <!-- <span class="pull-right bg-danger text-white px-2" v-if="mode == 'edit'">Harap salin url pada tombol ke Template Content</span> -->
        </div>
        <div class="clearfix"></div>
        <br />
        <div v-if="isLoading">
            <div class="d-flex justify-content-center">
                <b-spinner label="Loading..."></b-spinner>
            </div>
        </div>
        <v-form v-model="formValid" v-if="!isLoading">
            <div class="row">
                <div class="col-8">
                    <!-- {{ form.componentButton }} -->

                    <div class="form-group">
                        <!-- <label for="template_title" class="">
                            Judul
                        </label> -->
                        <!-- <div class="col-sm-12 col-md-8 mt-0 pt-0"> -->
                        <v-text-field class="pt-0" placeholder="Judul" id="template_title" v-model="form.title"
                            :rules="rules.required" append-icon="*" />
                        <!-- </div> -->
                    </div>
                    <div class="form-group">
                        <v-text-field class="pt-0" placeholder="Kode" id="template_code" v-model="form.code"
                            :rules="rules.noSymbol" append-icon="*" />
                    </div>
                    <div class="form-group">
                        <v-select class="pt-0" placeholder="Kategori Pesan" id="template_messageCategory"
                            :items="selectCategory" v-model="form.category" :rules="rules.required"
                            append-outer-icon="*"></v-select>
                    </div>
                    <div class="form-group">
                        <label for="template_enable" class="text-muted">
                            Aktif / Nonaktif
                        </label>
                        <div class="">
                            <v-switch id="template_enable" class="mt-0" v-model="form.enable" />
                        </div>
                    </div>
                    <div class="form-group">
                        <v-select placeholder="Kode Tindakan" class="pt-0" id="template_messageAction"
                            :items="selectActionCodes" v-model="form.actionCodes" multiple></v-select>
                    </div>
                    <div class="form-group">
                        <v-text-field placeholder=" Deskripsi Singkat" class="pt-0" id="template_shortDescription"
                            v-model="form.shortDescription" :rules="rules.required" append-icon="*" />
                    </div>
                </div>
                
                <div class="col-4 text-center">
                    <div class="phone phone-template" id="iphone">
                        <div class="camera"></div>
                        <div class="speaker"></div>
                        <div class="screen whatsapp-bg p-2">
                            <div class="bg-white mt-3">
                                <v-row class="p-2">
                                    <v-col cols="12" md="12" class="mt-0 pt-0"
                                        v-if="form.headerType != 'none' && form.headerType != null">
                                        <div v-if="form.headerType == 'text'" class="card-text">
                                            <p style="font-weight: bold">
                                                {{ form.headerText }}
                                            </p>
                                        </div>
                                        <div v-else>
                                            <img v-if="form.headerType == 'document'" class="img-fluid" src="../assets/images/icon/doc-icon.jpg" />
                                            <img v-if="form.headerType == 'video'" class="img-fluid" src="../assets/images/icon/video-icon.jpg" />
                                            <img v-if="form.headerType == 'image'" class="img-fluid" src="../assets/images/icon/image-icon.jpg" />
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="12" class="pt-0 pb-0">
                                        <div class="card-text">
                                            <div class="content-template" v-html="formatTabMessages(form.content)">
                                            </div>
                                            <!-- <br> -->
                                            <div class="content-template m-t-10 text-muted">
                                                {{ form.footer }}
                                            </div>
                                        </div>
                                    </v-col>
                                    <div class="clearfix"></div>
                                </v-row>
                                <hr class="hr-template" style="margin-bottom:0">
                                <div class="list-action-button" v-if="form.componentButton.length < 4">
                                    <div class="list-button" v-for="(item, index) in form.componentButton" :key="index">
                                        <a class="card-text p-2 text-center">
                                            <v-icon class="text-primary icon mr-1" style="font-size:14px !important">
                                                {{ item.buttonType == 'url' ? 'mdi-web' : item.buttonType == 'number' ? 'mdi-phone' : 'mdi-reply' }}
                                            </v-icon>
                                            {{ item.buttonType == 'url' || item.buttonType == 'number' ? item.template.text : item.defaultValue }}
                                            <div class="clearfix"></div>
                                        </a>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="list-action-button" v-else>
                                    <div class="list-button" v-for="(item, index) in form.componentButton.slice(0, 2)" :key="index">
                                        <a class="card-text p-2 text-center">
                                            <v-icon class="text-primary icon mr-1" style="font-size:14px !important">
                                                {{ item.buttonType == 'url' ? 'mdi-web' : item.buttonType == 'number' ? 'mdi-phone' : 'mdi-reply' }}
                                            </v-icon>
                                            {{ item.buttonType == 'url' || item.buttonType == 'number' ? item.template.text : item.defaultValue }}
                                            <div class="clearfix"></div>
                                        </a>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="list-button">
                                        <a class="card-text p-2 text-center">
                                            <v-icon class="text-primary icon mr-1" style="font-size:14px !important">
                                                mdi-menu
                                            </v-icon>
                                            See all options
                                            <div class="clearfix"></div>
                                        </a>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="btns"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">

                    <div class="form-group">
                        <!-- <label for="select_header" class="text-muted">
                            Template Header
                        </label> -->
                        <div class="m-b-20 font-weight-bold">Jenis Header</div>
                        <div class="">
                            <div>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on">
                                            {{ selectedHeader == null ? 'Tidak Ada' : selectedHeader }}
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(item, index) in selectHeader" :key="index" 
                                            @click="selectedHeader = item.title == 'Teks' ? 'text' : item.title == 'Media' ? 'media' : null"
                                        >
                                            <v-list-item-avatar>
                                                <v-icon v-if="item.title == 'Tidak Ada'">mdi-stop</v-icon>
                                                <v-icon v-if="item.title == 'Teks'">mdi-comment-outline</v-icon>
                                                <v-icon v-if="item.title == 'Media'">mdi-file-outline</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <div class="clearfix m-b-20"></div>
                                <div v-if="selectedHeader != null && selectedHeader != 'text'">
                                    <!-- <v-btn depressed x-large class="m-r-10" @click="form.headerType = 'text'">
                                         <v-icon left>
                                            mdi-comment
                                        </v-icon> 
                                        Text
                                    </v-btn> -->
                                     <v-btn depressed x-large class="m-r-10" @click="form.headerType = 'image'" 
                                        :color="form.headerType == 'image' ? 'primary' : 'default'">
                                         <v-icon left>
                                            mdi-image
                                        </v-icon> 
                                        Gambar
                                    </v-btn>
                                    <v-btn depressed x-large class="m-r-10" @click="form.headerType = 'video'"
                                        :color="form.headerType == 'video' ? 'primary' : 'default'">
                                         <v-icon left>
                                            mdi-video
                                        </v-icon> 
                                        Video
                                    </v-btn>
                                    <v-btn depressed x-large @click="form.headerType = 'document'"
                                        :color="form.headerType == 'document' ? 'primary' : 'default'">
                                         <v-icon left>
                                            mdi-file
                                        </v-icon> 
                                        Dokumen
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div v-if="form.headerType == 'text'">

                            <div class="">
                                <v-textarea placeholder="Konten Header" class="mt-0" id="template_header_text"
                                    v-model="form.headerText" :rules="rules.required" append-icon="*"></v-textarea>
                            </div>
                            <v-row class="mt-1">
                                <div class="col-sm-12 col-md-6">
                                    <span class="" v-if="form.headerType == 'text'">
                                        <v-hover v-slot="{ hover }" open-delay="200">
                                            <v-btn :color="hover ? 'secondary': ''"
                                                @click="customComponentHeader('add', null)">
                                                <v-icon>mdi-plus</v-icon>
                                                Parameter Header
                                            </v-btn>
                                        </v-hover>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span class="ml-2" v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-information-outline</v-icon>
                                                </span>
                                            </template>
                                            <div>
                                                Ketentuan parameter
                                                <ul class="ml-4">
                                                    <li>Digunakan untuk menambahkan informasi tambahan</li>
                                                    <li>Tidak boleh menggunakan karakter (@!#$&^%)</li>
                                                    <li>Tidak boleh mencantumkan spasi antar kata (a b)</li>
                                                </ul>
                                            </div>
                                        </v-tooltip>
                                    </span>
                                </div>
                            </v-row>
                            <v-row v-for="(item, index) in form.componentHeader" :key="index+1">
                                <div class="col-sm-12 col-md-3">
                                    <v-text-field v-model="item.paramName" placeholder="Nama Param"
                                        :rules="rules.required" append-icon="*" />
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <v-text-field v-model="item.paramCode" placeholder="Kode Param"
                                        :rules="rules.required" append-icon="*" />
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <v-text-field v-model="item.defaultValue" placeholder="Nilai Default"
                                        :rules="rules.required" append-icon="*" />
                                </div>
                                <div class="col-sm-12 col-md-1">
                                    <v-text-field v-model="item.paramIndex" readonly />
                                </div>
                                <div class="col-sm-12 col-md-1 mt-2">
                                    <v-icon color="error" @click="customComponentHeader('delete', index)">mdi-delete
                                    </v-icon>
                                </div>
                            </v-row>
                        </div>
                    </div>
                    <div class="form-group">
                        <v-row>
                            <div class="col-sm-12 col-md-12 mt-0 pt-0">
                                <v-textarea placeholder="Isi Konten" class="mt-0"
                                    id="template_standardTemplateText" v-model="form.content" :rules="rules.required"
                                    append-icon="*"></v-textarea>
                                <v-row class="mb-1 mt-1">
                                    <div class="col-sm-12 col-md-6">
                                        <v-hover v-slot="{ hover }" open-delay="200">
                                            <v-btn :color="hover ? 'secondary': ''"
                                                @click="customComponentContent('add', null)">
                                                <v-icon>mdi-plus</v-icon>
                                                Parameter Konten
                                            </v-btn>
                                        </v-hover>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span class="ml-2" v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-information-outline</v-icon>
                                                </span>
                                            </template>
                                            <div>
                                                Ketentuan parameter
                                                <ul class="ml-4">
                                                    <li>Digunakan untuk menambahkan informasi tambahan</li>
                                                    <li>Tidak boleh menggunakan karakter (@!#$&^%)</li>
                                                    <li>Tidak boleh mencantumkan spasi antar kata (a b)</li>
                                                </ul>
                                            </div>
                                        </v-tooltip>
                                    </div>
                                </v-row>
                            </div>
                        </v-row>
                    </div>
                    <div class="form-group">
                        <div class="row" v-for="(item, index) in form.componentContent" :key="'content ' + index">
                            <div class="col-sm-12 col-md-3">
                                <v-text-field v-model="item.paramName" placeholder="Nama Param" :rules="rules.required"
                                    append-icon="*" />
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <v-text-field v-model="item.paramCode" placeholder="Kode Param" :rules="rules.required"
                                    append-icon="*" />
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <v-text-field v-model="item.defaultValue" placeholder="Nilai Default" :rules="rules.required"
                                    append-icon="*" />
                            </div>
                            <div class="col-sm-12 col-md-1">
                                <v-text-field v-model="item.paramIndex" readonly />
                            </div>
                            <div class="col-sm-12 col-md-1 mt-2">
                                <v-icon color="error" @click="customComponentContent('delete', index)">mdi-delete
                                </v-icon>
                            </div>
                            <br>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="mb-1 mt-1">
                            <v-textarea placeholder="  Konten Footer" class="mt-0" id="template_standardTemplateText"
                                v-model="form.footer">
                            </v-textarea>
                            <!-- <v-row class="mb-1 mt-1">
                                <div class="col-sm-12 col-md-6">
                                    <v-hover v-slot="{ hover }" open-delay="200">
                                        <v-btn :color="hover ? 'secondary': ''"
                                            @click="customComponentFooter('add', null)">
                                            <v-icon>mdi-plus</v-icon>
                                            Parameter
                                        </v-btn>
                                    </v-hover>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="ml-2" v-bind="attrs" v-on="on">
                                                <v-icon>mdi-information-outline</v-icon>
                                            </span>
                                        </template>
                                        <div>
                                            Ketentuan parameter
                                            <ul class="ml-4">
                                                <li>Digunakan untuk menambahkan informasi tambahan</li>
                                                <li>Tidak boleh menggunakan karakter (@!#$&^%)</li>
                                                <li>Tidak boleh mencantumkan spasi antar kata (a b)</li>
                                            </ul>
                                        </div>
                                    </v-tooltip>
                                </div>
                            </v-row> -->
                        </div>
                    </div>

                    <!-- <div class="row" v-for="(item, index) in form.componentFooter" :key="'footer ' + index">
                        <div class="col-sm-12 col-md-4">
                            <v-text-field v-model="item.paramName" placeholder="Nama Param" :rules="rules.required"
                                append-icon="*" />
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <v-text-field v-model="item.paramCode" placeholder="Kode Param" :rules="rules.required"
                                append-icon="*" />
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <v-text-field v-model="item.paramIndex" readonly />
                        </div>
                        <div class="col-sm-12 col-md-2 mt-2">
                            <v-icon color="error" @click="customComponentFooter('delete', index)">mdi-delete</v-icon>
                        </div>
                    </div> -->

                    <div class="form-group m-t-40">
                        <div class="m-b-20 font-weight-bold">Pilih Tombol</div>
                        <div class="clearfix"></div>
                        <div class="">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-hover v-slot="{ hover }" open-delay="200">
                                        <v-btn variant :color="hover ? 'secondary': ''" v-bind="attrs" v-on="on">
                                            <v-icon>mdi-plus</v-icon> Tombol
                                        </v-btn>
                                    </v-hover>
                                </template>
                                <v-list class="list-button-comp">
                                    <v-list-item>
                                        <v-list-item-title @click="customComponentButton('add', null, 'link')">Link
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title @click="customComponentButton('add', null, 'call')">Panggil
                                            Saya</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title @click="customComponentButton('add', null, 'quick')">Balas
                                            Cepat</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                    <div class="row mt-4" v-for="(item, index) in form.componentButton" :key="'button ' + index">
                        <!-- <label for="f-password" class="col-sm-12 col-md-3 text-right control-label col-form-label">
                            {{ item.buttonType == 'url' ? 'Tombol Link' : item.buttonType == 'number' ? 'Tombol Panggil Saya' : 'Tombol Balas Cepat'}}
                        </label> -->

                        <div class="col-sm-12 col-md-2">
                            <v-text-field v-model="item.paramName" label="Nama Param" :rules="rules.required"
                                append-icon="*" />
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <v-text-field v-model="item.paramCode" label="Kode Param" :rules="rules.required"
                                append-icon="*" />
                        </div>

                        <!-- button url -->
                        <div class="col-sm-12 col-md-3" v-if="item.buttonType == 'url'">
                            <v-text-field v-model="item.template.text" label="Nama Tombol Website" :rules="rules.required"
                                append-icon="*" />
                        </div>
                        <!-- button url -->

                        <!-- button call -->
                        <div class="col-sm-12 col-md-3" v-if="item.buttonType == 'number'">
                            <v-text-field v-model="item.template.text" label="Nama Tombol Telepon" :rules="rules.required"
                                append-icon="*" />
                        </div>
                        <!-- button call -->

                        <div class="col-sm-12 col-md-3">
                            <v-text-field v-model="item.defaultValue" label="Nilai Default" :rules="rules.required" />
                        </div>
                        <div class="col-sm-12 col-md-1">
                            <v-text-field v-model="item.paramIndex" readonly />
                        </div>
                        <div class="col-sm-12 col-md-1 mt-2">
                            <v-icon color="error" @click="customComponentButton('delete', index)">mdi-delete</v-icon>
                        </div>
                        <br>
                    </div>
                    <div class="row m-b-10">
                        <div class="col-sm-12 col-md-9">
                            <v-btn color="deep-purple accent-4" class="m-r-10 text-white" v-if="mode == 'add'"
                                @click="postTemplate" :disabled="!formValid" :loading="formIsLoading">
                                <span class="text-white">Simpan</span>
                            </v-btn>
                            <v-btn color="deep-purple accent-4" class="m-r-10 text-white" v-else @click="putTemplate"
                                :disabled="!formValid" :loading="formIsLoading">
                                <span class="text-white">Simpan</span>
                            </v-btn>
                            <router-link :to="{ name: 'chatsetting' }">
                                <v-btn class="m-r-10">Kembali</v-btn>
                            </router-link>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </v-form>

        <v-dialog 
            v-model="showDialog" 
            transition="dialog-bottom-transition" 
            max-width="600"
            @click:outside="closeDialog" 
            @keydown.esc="closeDialog"
        >
            <template>
                <v-card>
                    <v-toolbar color="deep-purple accent-4" dark class="text-h6 text-white"
                        style="background-color:red">
                        <span class="text-white">Salin Broadcast</span>
                    </v-toolbar>
                    <v-form v-model="formValidDialog">
                        <v-card-text>
                            <v-select
                                v-model="selectedChannel"
                                :items="channels"
                                item-value="_id"
                                item-text="name"
                                label="Kanal"
                                :rules="rules.required"
                            ></v-select>
                            <v-file-input v-if="selectedHeader == 'media'"
                                v-model="fileInput"
                                truncate-length="15"
                                :rules="rules.required"
                            ></v-file-input>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn @click="closeDialog">
                                Kembali
                            </v-btn>
                            <v-btn class="ml-2 btn btn-primary" style="color: white;" :loading="isLoadingSubmit"
                                :disabled="!formValidDialog" @click="submitTemplate">
                                Submit
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>
<script>
    import axios from 'axios'
    import Toast from '../plugins/vuenotification'

    const uuidv4 = require('uuid/v4');
    const template = require('lodash.template');

    export default {
        props: ['showPage', 'channels'],

        components: {},

        data: function () {
            return {
                selectCategory: ['MARKETING', 'NOTIFICATION', 'NEWS', 'AUTHENTICATION', 'UTILITY'],
                selectActionCodes: ['SUBSCRIBE', 'UNSUBSCRIBE'],
                selectHeader: [
                    { title: 'Tidak Ada' },
                    { title: 'Teks' },
                    { title: 'Media' }
                ],

                selectedChannel: String(),

                selectedHeader: null,
                fileInput: null,

                form: {
                    _id: String(),
                    title: String(),
                    code: String(),
                    category: String(),
                    enable: true,
                    headerType: null,
                    headerText: String(),
                    actionCodes: Array(),
                    shortDescription: String(),
                    content: String(),
                    footer: String(),
                    componentHeader: Array(),
                    componentContent: Array(),
                    // componentFooter: Array(),
                    componentButton: Array(),
                    cloud: null,
                },

                mode: 'add',

                formValid: false,
                isLoading: false,
                isLoadingSubmit: false,
                formIsLoading: false,
                showDialog: false,
                formValidDialog: false,

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                    noSymbol: [(v) => /^[a-z0-9_]+$/.test(v) ||
                        "Kode template tidak boleh memakai simbol atau kosong"
                    ]
                },
            }
        },

        methods: {
            closeDialog: function() {
                this.showDialog = false
                this.selectedChannel = String()
                this.fileInput = null
            },
            submitTemplate: async function() {
                try {
                    this.isLoadingSubmit = true

                    const formData = new FormData();
                    formData.append('file', this.fileInput);
                    formData.append('channelId', this.selectedChannel);

                    await axios.post(process.env.VUE_APP_API_URL + '/template/submit/' + this.form._id, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    })

                    this.isLoadingSubmit = false
                    this.closeDialog()
                    this.selectTemplate(this.form.code)
                } catch (error) {
                    this.isLoadingSubmit = false
                    console.log("error-submit-template", error)
                }
            },
            selectTemplate: async function (code) {
                this.isLoading = true
                try {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/template/' + code)
                    
                    let form = {
                        _id: data._id,
                        code: data.code,
                        category: data.messageCategory,
                        title: data.title,
                        headerType: null,
                        cloud: data.cloud,
                        actionCodes: data.actionCodes,
                        shortDescription: data.shortDescription,
                        enable: data.enable,
                        content: data.bodyTemplateText,
                        footer: data.footerTemplateText,
                        headerText: data.headerTemplateText,
                        componentHeader: [],
                        componentContent: [],
                        // componentFooter: [],
                        componentButton: [],
                    }

                    if (data.contentType != 'text') {
                        form.headerType = data.contentType
                        this.selectedHeader = 'media'
                    } else if (data.contentType == 'text' && data.headerTemplateText == null) {
                        form.headerType = null
                    } else {
                        this.selectedHeader = 'text'
                        form.headerType = data.contentType
                    }

                    if (data.contentType == 'text') form.componentHeader = data.components.filter(e => e.compType ==
                        'header');
                    form.componentContent = data.components.filter(e => e.compType == 'body');
                    // form.componentFooter = data.components.filter(e => e.compType == 'footer');
                    form.componentButton = data.components.filter(e => e.compType == 'button');
                    this.form = form

                } catch (error) {
                    console.log("select template", error);
                }
                this.isLoading = false
            },
            putTemplate: async function () {
                this.formIsLoading = true
                const valid = this.formIsValid()

                if (!valid) {
                    this.formIsLoading = false
                    return
                }

                try {
                    let data = {
                        _id: this.form._id,
                        code: this.form.code,
                        messageCategory: this.form.category,
                        title: this.form.title,
                        actionCodes: this.form.actionCodes,
                        shortDescription: this.form.shortDescription,
                        headerTemplateText: null,
                        bodyTemplateText: this.form.content,
                        footerTemplateText: this.form.footer,
                        enable: this.form.enable,
                        components: [],
                    }

                    if (this.form.headerType != null) {
                        if (this.form.headerType != 'text') {
                            data.components.push({
                                "compType": "header",
                                "paramName": this.form.headerType[0].toUpperCase() + this.form.headerType
                                    .slice(1) + " ID",
                                "paramCode": this.form.headerType + "Id",
                                "paramIndex": 1,
                                "paramType": this.form.headerType
                            })
                        } else if (this.form.headerType == 'text') {
                            data.headerTemplateText = this.form.headerText.trim()
                            data.components = [...data.components, ...this.form.componentHeader]
                        }
                    }

                    const componentButton = this.form.componentButton.map(e => {
                        if (e.buttonType == 'url') e.template.url = `<%= ${e.paramCode} %>`
                        if (e.buttonType == 'number') e.template.number = `<%= ${e.paramCode} %>`
                        if (e.buttonType == 'text') e.template.text = `<%= ${e.paramCode} %>`
                        return e
                    })

                    data.components = [
                        ...data.components,
                        ...this.form.componentContent,
                        // ...this.form.componentFooter,
                        ...componentButton
                    ].map(e => {
                        e.paramCode = e.paramCode.trim()
                        return e
                    })

                    await axios.put(process.env.VUE_APP_API_URL + '/template', data)

                    this.$router.push('/autochatsetting')
                } catch (error) {
                    Toast.showToast("error", error.response.data.message)
                    console.log("error edit tempalte", error);
                }
                this.formIsLoading = false
            },
            postTemplate: async function () {
                this.formIsLoading = true
                const valid = this.formIsValid()

                if (!valid) {
                    this.formIsLoading = false
                    return
                }

                try {
                    let data = {
                        _id: uuidv4(),
                        code: this.form.code,
                        messageCategory: this.form.category,
                        title: this.form.title,
                        actionCodes: this.form.actionCodes,
                        shortDescription: this.form.shortDescription,
                        headerTemplateText: null,
                        bodyTemplateText: this.form.content,
                        footerTemplateText: this.form.footer,
                        enable: this.form.enable,
                        components: [],
                    }

                    if (this.form.headerType != null) {
                        if (this.form.headerType != 'text') {
                            data.components.push({
                                "compType": "header",
                                "paramName": this.form.headerType[0].toUpperCase() + this.form.headerType
                                    .slice(1) + " ID",
                                "paramCode": this.form.headerType + "Id",
                                "paramIndex": 1,
                                "paramType": this.form.headerType
                            })
                        } else if (this.form.headerType == 'text') {
                            data.headerTemplateText = this.form.headerText.trim()
                            data.components = [...data.components, ...this.form.componentHeader]
                        }
                    }

                    const componentButton = this.form.componentButton.map(e => {
                        if (e.buttonType == 'url') e.template.url = `<%= ${e.paramCode} %>`
                        if (e.buttonType == 'number') e.template.number = `<%= ${e.paramCode} %>`
                        if (e.buttonType == 'text') e.template.text = `<%= ${e.paramCode} %>`
                        return e
                    })

                    data.components = [
                        ...data.components,
                        ...this.form.componentContent,
                        // ...this.form.componentFooter,
                        ...componentButton
                    ].map(e => {
                        e.paramCode = e.paramCode.trim()
                        return e
                    })

                    await axios.post(process.env.VUE_APP_API_URL + '/template', data)

                    this.$router.push('/autochatsetting')
                } catch (error) {
                    Toast.showToast("error", error.response.data.message)
                    console.log("error add tempalte", error);
                }
                this.formIsLoading = false
            },
            formIsValid: function () {
                try {
                    let param = Object()
                    const components = [
                        ...this.form.componentHeader,
                        ...this.form.componentContent,
                        // ...this.form.componentFooter,
                        ...this.form.componentButton
                    ].map(e => {
                        e.paramCode = e.paramCode.trim()
                        return e
                    })

                    // check param duplicate & space 
                    if (components.length > 0) {
                        let duplicate = false;
                        let space = false;

                        for (let i = 0; i < components.length; i++) {
                            if (components[i].paramCode.includes(" ")) {
                                space = true
                                break;
                            }
                            for (let j = i + 1; j < components.length; j++) {
                                if (components[i].paramCode === components[j].paramCode) {
                                    duplicate = true;
                                    break;
                                }
                            }
                        }
                        if (space) throw new Error("kode tidak boleh menggunakan spasi")
                        if (duplicate) throw new Error("kode tidak boleh sama")
                    }

                    // check param header
                    if (this.form.headerType == 'text') {
                        this.form.componentHeader.forEach((e, i) => param[e.paramCode] = i)
                        template(this.form.headerText)({
                            ...param
                        })
                    }

                    // check param content
                    param = Object()
                    this.form.componentContent.forEach((e, i) => param[e.paramCode] = i)
                    template(this.form.content)({
                        ...param
                    })

                    // check param footer
                    // param = Object()
                    // this.form.componentFooter.forEach((e, i) => param[e.paramCode] = i)
                    // template(this.form.footer)({
                    //     ...param
                    // })

                    return true
                } catch (error) {
                    Toast.showToast("error", "Param " + error.message)
                    console.log('valid-form', error.message);
                    return false
                }
            },
            resetToEmptyForm: function () {
                this.form = {
                    _id: String(),
                    title: String(),
                    code: String(),
                    category: String(),
                    enable: true,
                    headerType: null,
                    headerText: String(),
                    actionCodes: Array(),
                    shortDescription: String(),
                    content: String(),
                    footer: String(),
                    componentHeader: Array(),
                    componentContent: Array(),
                    // componentFooter: Array(),
                    componentButton: Array()
                }
            },
            customComponentHeader: function (action, index) {
                let form = {
                    ...this.form
                }

                if (action == 'add') {
                    form.componentHeader.push({
                        "compType": "header",
                        "paramName": String(),
                        "paramCode": String(),
                        "paramIndex": form.componentHeader.length + 1,
                        "defaultValue": String(),
                        "paramType": "text"
                    })
                } else {
                    form.componentHeader.splice(index, 1)
                    for (let i = index; i < form.componentHeader.length; i++) form.componentHeader[i].paramIndex =
                        i + 1;
                }

                this.form = form
            },
            customComponentContent: function (action, index) {
                let form = {
                    ...this.form
                }

                if (action == 'add') {
                    form.componentContent.push({
                        "compType": "body",
                        "paramName": String(),
                        "paramCode": String(),
                        "paramIndex": form.componentContent.length + 1,
                        "defaultValue": String(),
                        "paramType": "text"
                    })
                } else {
                    form.componentContent.splice(index, 1)
                    for (let i = index; i < form.componentContent.length; i++) form.componentContent[i].paramIndex =
                        i + 1;
                }

                this.form = form
            },
            // customComponentFooter: function (action, index) {
            //     let form = {
            //         ...this.form
            //     }

            //     if (action == 'add') {
            //         form.componentFooter.push({
            //             "compType": "footer",
            //             "paramName": String(),
            //             "paramCode": String(),
            //             "paramIndex": form.componentFooter.length + 1,
            //             "defaultValue": String(),
            //             "paramType": "text"
            //         })
            //     } else {
            //         form.componentFooter.splice(index, 1)
            //         for (let i = index; i < form.componentFooter.length; i++) form.componentFooter[i].paramIndex =
            //             i + 1;
            //     }

            //     this.form = form
            // },
            customComponentButton: function (action, index, buttonType) {
                let form = {
                    ...this.form
                }

                if (action == 'add') {
                    if (buttonType == 'link') {
                        form.componentButton.push({
                            "compType": "button",
                            "paramName": String(),
                            "paramCode": String(),
                            "paramIndex": form.componentButton.length + 1,
                            "paramType": "text",
                            "buttonType": "url",
                            "template": {
                                "text": String(),
                                "url": String(),
                            }
                        })
                    } else if (buttonType == 'call') {
                        form.componentButton.push({
                            "compType": "button",
                            "paramName": String(),
                            "paramCode": String(),
                            "paramIndex": form.componentButton.length + 1,
                            "paramType": "text",
                            "buttonType": "number",
                            "template": {
                                "text": String(),
                                "number": String(),
                            }
                        })
                    } else if (buttonType == 'quick') {
                        form.componentButton.push({
                            "compType": "button",
                            "paramName": String(),
                            "paramCode": String(),
                            "paramIndex": form.componentButton.length + 1,
                            "paramType": "text",
                            "buttonType": "text",
                            "template": {
                                "text": String(),
                            }
                        })
                    }
                } else {
                    form.componentButton.splice(index, 1)
                    for (let i = index; i < form.componentButton.length; i++) form.componentButton[i].paramIndex =
                        i + 1;
                }

                this.form = form
            },
            loadInitialData: async function () {
                if (this.$route.params.id != null) {
                    this.mode = 'edit'
                    this.selectTemplate(decodeURIComponent(this.$route.params.id));
                } else {
                    this.mode = 'add'
                }
            },
            formatTabMessages(message) {
                let temp = message

                let current = true

                // check bold
                const isBold = temp.split('').includes('*')
                if (isBold) {
                    temp = temp.split('').map(e => {
                        if (e == '*') {
                            if (current) {
                                current = false
                                return '<span style="font-weight: bold;">'
                            } else {
                                current = true
                                return '</span>'
                            }
                        }

                        return e
                    }).join('')
                }

                // check Strikethrough
                const isStrikethrough = temp.split('').includes('~')
                if (isStrikethrough) {
                    current = true
                    temp = temp.split('').map(e => {
                        if (e == '~') {
                            if (current) {
                                current = false
                                return '<s>'
                            } else {
                                current = true
                                return '</s>'
                            }
                        }

                        return e
                    }).join('')
                }

                return temp ? temp.split('\n').join('<br />') : temp
            }
        },

        watch: {
            selectedHeader: function(val) {
                if(val == 'text') {
                    this.form.headerType = val
                } else {
                    this.form.headerType = null
                }
            },
            $route: async function (to, from) {
                if (this.$helpers.isBlank(to.params.id)) {
                    this.resetToEmptyForm();
                    this.mode = 'add'
                } else if (to.params.id != from.params.id) {
                    this.selectTemplate(decodeURIComponent(to.params.id));
                    this.mode = 'edit'
                }
            },
        },

        mounted: async function () {
            this.loadInitialData();
        },
    }
</script>