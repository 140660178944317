<template>
    <div v-if="showPage == 'detail'">
        <div v-if="isLoadingForm">
            <div class="d-flex justify-content-center">
                <b-spinner label="Loading..."></b-spinner>
            </div>                                                                                                                                              
        </div>
        <div v-if="!isLoadingForm">
            <span class="pull-left"><h2>{{ this.title }}</h2></span>
            <span class="pull-right badge badge-info"><h6 class="mb-0 text-white">{{ statusBroadcast }}</h6></span>
            <br>
            <div class="clearfix"></div>
            <div>
                Kode template: <span style="font-weight:bold;">{{ templateCode }}</span>
            </div>
            <div>
                Kanal: <span style="font-weight:bold;">{{ selectedChannel.name }}</span> 
            </div>
            <br>
            <div class="clearfix"></div>
            <div class="row">
                <div class="col-md-6">
                    <ChartPie 
                        :chartId="chart.id"
                        :datasetIdKey="chart.datasetIdKey"
                        :width="chart.width"
                        :height="chart.height"
                        :cssClasses="chart.cssClasses"
                        :styles="chart.styles"
                        :plugins="chart.plugins"
                        :chartValue="deliverySummary"
                        :broadcastId="idBroadcast"
                    />
                </div>
                <div class="col-md-6">
                    <div class="row" v-if="statusBroadcast == 'DONE'">
                        <div class="col-md-8">
                            <v-select v-model="selectedStatus" :items="status" label="Status Broadcast" :loading="isLoadingTable"></v-select>
                        </div>
                        <div class="col-md-4 pt-8">
                            <div class="row">
                                <v-btn @click="filterTable" class="mr-2 text-white btn btn-primary">
                                    Cari
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <p class="pb-2" v-if="statusBroadcast != 'DRAFT'">Progress Broadcast {{ countDestinations }}/{{ totalDestinations }}</p>
                    <div class="pb-2" v-if="statusBroadcast != 'DRAFT' && statusBroadcast != 'DONE'">
                        <v-progress-linear
                            :value="knowledge"
                            height="25"
                            class="text-white"
                            disabled
                        >
                            <strong>{{ Math.ceil(knowledge) }}%</strong>
                        </v-progress-linear>
                    </div>
                    <v-data-table :headers="contactHeaders" :items="filterBroadcastDestinations" :loading="isLoadingTable" class="elevation-1">
                        <template v-slot:item.messageStatus="{ item }">
                            <div v-if="item.messageStatus">
                                {{ 
                                    item.messageStatus == 'delivered' ? 'Terkirim' : 
                                    item.messageStatus == 'error' ? 'Tidak Terkirim' : 
                                    item.messageStatus == 'pending' ? 'Menunggu Dikirim' :
                                    item.messageStatus == 'queued' ? 'Sedang Dikirim' :
                                    item.messageStatus == 'read' ? 'Dibaca' :
                                    item.messageStatus == 'sent' ? 'Terkirim Ke Tujuan' :
                                    item.messageStatus == 'unsubscribed' ? 'Berhenti Berlangganan' : ''
                                }}
                                {{ " - " + item.time }}
                            </div>
                        </template>
                    </v-data-table>
                </div>
                
            </div>
            <div class="pull-right">
            <router-link :to="{ name: 'broadcast' }">
                <v-btn class="m-r-10">
                    Kembali
                </v-btn>
            </router-link>
            <v-btn @click="broadcastAction(idBroadcast, 'resend')" class="m-r-10 text-white btn btn-primary"  
                :loading="isLoading" 
                v-if="statusBroadcast == 'DONE'"
                :disabled="countDestinations == totalDestinations">
                Resend Pending
            </v-btn>
            <v-btn @click="broadcastAction(idBroadcast, 'publish')" class="m-r-10 text-white btn btn-primary"
                v-if="statusBroadcast != 'DONE'" 
                :loading="isLoading || statusBroadcast == 'RUNNING'"
                :disabled="statusBroadcast == 'RUNNING' || statusBroadcast == 'PAUSED' || statusBroadcast == 'CANCELED'">
                Publish
            </v-btn>
            <v-btn @click="broadcastAction(idBroadcast, 'pause')" class="m-r-10" 
                :loading="isLoading" 
                v-if="statusBroadcast == 'RUNNING' || statusBroadcast == 'PAUSED'" :disabled="statusBroadcast == 'PAUSED'">
                Pause
            </v-btn>
            <v-btn @click="broadcastAction(idBroadcast, 'resume')" class="m-r-10"  
                :loading="isLoading" 
                v-if="statusBroadcast == 'RUNNING' || statusBroadcast == 'PAUSED'" :disabled="statusBroadcast != 'PAUSED'">
                Resume
            </v-btn>
            <v-btn @click="broadcastAction(idBroadcast, 'cancel')" class="m-r-10 "
                :loading="isLoading" 
                v-if="statusBroadcast == 'RUNNING' || statusBroadcast == 'PAUSED'">
                Cancel
            </v-btn>
            <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import ChartPie from './ChartPie.vue'
import { mapState } from 'vuex';

const { toFriendlyDateTime } = require('../utils/date-utils');

export default {
    props: ['showPage', 'channelList'],

    components: {
        ChartPie
    },

    computed: {
        ...mapState({
            broadcastCallback: state => state.socket.broadcastCallback,
        })
    },

    data: function() {
        return {
            idBroadcast: String(),
            title: String(),
            selectedChannel: Object(),
            statusBroadcast: String(),
            broadcastDestinations: Array(),
            filterBroadcastDestinations: Array(),
            templateCode: String(),
            totalDestinations: 0,
            countDestinations: 0,
            knowledge: 0,
            selectedStatus: "None",

            status: ["None", "Sedang Dikirim", "Menunggu Dikirim", "Sampai Ke Tujuan", "Terkirim", "Dibaca", "Tidak Terkirim", "Berhenti Berlangganan"],
            // status: ["None", "queued", "pending", "sent", "delivered", "read", "error", "unsubscribed"],

            chart: {
                id: 'broadcast',
                datasetIdKey: 'label',
                width: 400,
                height: 400,
                cssClasses: String(),
                styles: Object(),
                plugins: Array(),
            },

            deliverySummary: {
                "queued": 0,
                "pending": 0,
                "sent": 0,
                "delivered": 0,
                "read": 0,
                "error": 0,
                "unsubscribed": 0,
            },

            contactHeaders: [
                { text: 'Nama', value: 'name', sortable: false },
                { text: 'Status', value: 'messageStatus', sortable: false },
            ],

            isLoading: false,
            isLoadingTable: false,
            isLoadingForm: false,

            rules: {
                required: [(v) => !!v || ""],
                requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                requiredArray: [(v) => v.length > 0 || ""],
            },
        }
    },

    methods: {
        selectBroadcast: async function(id) {
            this.isLoadingForm = true 
            
            this.$socket.emit('BROADCAST_STATUS_REQUEST', {
                "broadcastId": id
            })

            try {
                const { data } = await axios.get(process.env.VUE_APP_API_URL + '/broadcast/' + id)
                
                this.idBroadcast = data._id
                this.statusBroadcast = data.status
                this.title = data.title
                this.templateCode = data.messageTemplate.code

                const temp = data.broadcastDestinations.map(e => {
                    e.time = toFriendlyDateTime(e.sentOn)
                    // e.time = moment(e.sentOn).locale('id').format('LLLL')
                    return e
                })

                this.selectedChannel = this.channelList.find(e => e._id == data.channelId)
                this.filterBroadcastDestinations = temp
                this.broadcastDestinations = temp

                if(data.deliverySummary) {
                    this.deliverySummary = data.deliverySummary

                    const sent = data.deliverySummary.sent ? data.deliverySummary.sent : 0
                    const error = data.deliverySummary.error ? data.deliverySummary.error : 0
                    const delivered = data.deliverySummary.delivered ? data.deliverySummary.delivered : 0
                    const read = data.deliverySummary.read ? data.deliverySummary.read : 0
                    const unsubscribed = data.deliverySummary.unsubscribed ? data.deliverySummary.unsubscribed : 0

                    this.countDestinations = delivered + read + sent + error + unsubscribed
                    if(this.countDestinations > 0) this.knowledge = Math.ceil((this.countDestinations / data.publishedToContactCount) * 100)
                }

                this.totalDestinations = data.publishedToContactCount
            } catch (error) {
                console.log("detail brodcast", error);
            }

            this.isLoadingForm = false 
        },
        broadcastAction: async function(id, action) {
            this.isLoading = true
            try {
                const { data } = await axios.put(process.env.VUE_APP_API_URL + '/broadcast/'+ id + '/' + action, {})

                this.statusBroadcast = data.status

            } catch (error) {
                console.log("publish", error);
            }
            this.isLoading = false
        },
        loadInitialData: async function () {
            if (this.$route.params.id != null) {
                if(this.$route.query.tabs == 'detail') {
                    await this.selectBroadcast(decodeURIComponent(this.$route.params.id));
                } 
            }
        },
        filterTable: function() {
            this.isLoadingTable = true
            const status = this.selectedStatus
            let selectedStatus = status == 'Sedang Dikirim' ? 'queued' :
            status == 'Menunggu Dikirim' ? 'pending' : 
            status == 'Sampai Ke Tujuan' ? 'sent' : 
            status == 'Terkirim' ? 'delivered' : 
            status == 'Dibaca' ? 'read' : 
            status == 'Tidak Terkirim' ? 'error' : 
            status == 'Berhenti Berlangganan' ? 'unsubscribed' : ''
            // status: ["None", "Sedang Dikirim", "Menunggu Dikirim", "Sampai Ke Tujuan", "Terkirim", "Dibaca", "Tidak Terkirim", "Berhenti Berlangganan"],
            // status: ["None", "queued", "pending", "sent", "delivered", "read", "error", "unsubscribed"],

            if(!selectedStatus) {
                this.filterBroadcastDestinations = this.broadcastDestinations
            } else {
                this.filterBroadcastDestinations = this.broadcastDestinations.filter(e => e.messageStatus == selectedStatus)
            }

            this.isLoadingTable = false
        },
        reset: function() {
            this.idBroadcast = String()
            this.title = String()
            this.statusBroadcast = String()
            this.broadcastDestinations = Array()
            this.filterBroadcastDestinations = Array()
            this.selectedStatus = String()

            this.deliverySummary = {
                "queued": 0,
                "pending": 0,
                "sent": 0,
                "delivered": 0,
                "read": 0,
                "error": 0,
                "unsubscribed": 0,
            },

            this.totalDestinations = 0
            this.countDestinations = 0
            this.knowledge = 0
        }
    },

    mounted: function () {
        this.loadInitialData();
    },

    watch: {
        showPage: function(val) {
            if(val == 'detail') {
                this.selectBroadcast(decodeURIComponent(this.$route.params.id))
            } else {
                this.reset()
            }
        },
        broadcastCallback: function(val) {
            if(this.showPage == 'detail') {
                if(this.idBroadcast == val.id) {
                    this.statusBroadcast = val.status
                    const temp = this.broadcastDestinations
                    const index = temp.findIndex(e => e._id == val.contactSent._id)

                    if(index != -1) {
                        const updatedData = val.contactSent
                        updatedData.time = toFriendlyDateTime(updatedData.sentOn)

                        temp.splice(index, 1)
                        temp.unshift(updatedData)
                        this.broadcastDestinations = temp
                    }

                    if(val.deliverySummary) {
                        const sent = val.deliverySummary.sent ? val.deliverySummary.sent : 0
                        const error = val.deliverySummary.error ? val.deliverySummary.error : 0
                        const delivered = val.deliverySummary.delivered ? val.deliverySummary.delivered : 0
                        const read = val.deliverySummary.read ? val.deliverySummary.read : 0
                        const unsubscribed = val.deliverySummary.unsubscribed ? val.deliverySummary.unsubscribed : 0

                        this.countDestinations = delivered + read + sent + error + unsubscribed
                        if (this.countDestinations > 0) this.knowledge = Math.ceil((this.countDestinations / this.totalDestinations) * 100)
                        
                        if (this.countDestinations == this.totalDestinations) this.statusBroadcast = 'DONE'
                    }
                }
            }
        },
    },
}

</script>
