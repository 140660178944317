<template>
    <v-dialog 
        v-model="showDialog"
        transition="dialog-bottom-transition" max-width="600"
        @click:outside="closeDialog"
        @keydown.esc="closeDialog"
    >
        <template>
            <v-card>
                <v-toolbar color="deep-purple accent-4" dark class="text-h6 text-white" style="background-color:red">
                    <span class="text-white">Salin Broadcast</span>
                </v-toolbar>
                <v-form v-model="formValid">
                    <v-card-text>
                        <v-text-field
                            v-model="cloneTitle"
                            label="Judul Baru"
                            :rules="rules.required">
                            </v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn @click="closeDialog">
                            Kembali
                        </v-btn>
                        <v-btn class="ml-2 btn btn-primary" style="color: white;"
                            :loading="isLoading"
                            :disabled="!formValid"
                            @click="cloneBroadcast"
                        >
                            Salin
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </template>
    </v-dialog>
</template>
<script>
import axios from 'axios'

export default {
    props: ['showDialog', 'broadcastId'],

    components: {},

    data: function() {
        return {
            cloneTitle: String(),
            
            formValid: false,
            isLoading: false,

            rules: {
                required: [(v) => !!v || ""],
                requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                requiredArray: [(v) => v.length > 0 || ""],
            },
        }
    },

    methods: {
        cloneBroadcast: async function() {
            this.isLoading = true

            const postData = {
                title: this.cloneTitle
            }

            try {
                const { data } = await axios.post(process.env.VUE_APP_API_URL + '/broadcast/'+ this.broadcastId +'/clone', postData)

                this.closeDialog()

                this.$router.push({
                    name: 'broadcast',
                    params: { id: encodeURIComponent(data._id) }
                });
            } catch (error) {
                console.log('clone-broadcast', error);
            }
            this.isLoading = false
        },
        closeDialog: function() {
            this.$emit('close-dialog', 'copy')
        },
    },

    watch: {},
}

</script>
