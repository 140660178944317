<template>
    <v-row justify="space-around">
        <v-col cols="auto">
            <v-dialog v-model="dialogTestTemplate" 
                persistent
                @keydown.esc="closeDialog"
                transition="dialog-bottom-transition" max-width="600"
            >
                <template>
                    <v-card>
                        <v-toolbar color="deep-purple accent-4" class="text-h6 text-white" style="background-color:red">
                            <span class="text-white">Test Template</span>
                        </v-toolbar>
                        <v-form v-model="formValid">
                            <v-card-text class="">
                                <div v-if="broadcast.header.type != 'text'">
                                    <div v-if="broadcast.header.type == 'image'" class="row">
                                        <div class="col-md-12 p-0" style="margin-right:0">
                                            <div class="list-img-upload" v-if="selectedInputAttachment.length > 0">
                                                <div class="card card-img" v-for="(image, index) in selectedInputAttachment" :key="image._id">
                                                    <img class="images-preview-modal img-responsive" 
                                                        v-bind:src="image.url"
                                                        alt="attachment" />

                                                    <div class="wrap-action-img">
                                                        &nbsp;
                                                        <a class="delete-link" @click="selectedInputAttachment.splice(index, 1);">
                                                            <i class="fa fa-close" title="Hapus"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="col-md-12 list-video pt-0" v-for="(e, index) in selectedInputAttachment" :key="e._id">
                                            <div class="row">
                                                <div class="col-md-5" style="width:85%">
                                                    <span class="icon-ext pull-left m-r-10">
                                                        <i class="mdi" :class="{
                                                            'mdi-file-document': broadcast.header.type == 'document',
                                                            'mdi-file-video': broadcast.header.type == 'video'
                                                        }"></i>
                                                    </span>
                                                    <div class="wrapp-title-doc">{{ e.fileName }}</div>
                                                </div>
                                                <div class="col-md-1">
                                                    <a title="hapus" @click="selectedInputAttachment.splice(index, 1);">
                                                        <span class="text-danger">
                                                            <i title="Hapus" class="fa fa-close"></i>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <v-img v-for="image in selectedInputAttachment" :key="image._id"
                                        :src="image.url"
                                        max-height="300"
                                    ></v-img> -->
                                    <!-- <video
                                        v-if="(broadcast.header.type == 'video' && broadcast.header.value)"
                                        style="max-width:100%"
                                        :src="broadcast.header.value"
                                        controls></video>
                                    <a v-if="(broadcast.header.type == 'document' && broadcast.header.value)"
                                        :href="broadcast.header.value"
                                        target="_blank">
                                        <i class="fa fa-file"
                                            :title="broadcast.header.value"></i>
                                        <span>Dokumen</span>
                                    </a> -->
                                    <br />
                                </div>
                                <div v-for="item in selectedTemplate.components"
                                    :key="item.paramCode">
                                    <!-- <v-text-field
                                        v-if="(item.buttonType == 'number')"
                                        v-model="item.value"
                                        :label="item.paramName"
                                        type="number"
                                        :rules="[rules.required, rules.counter]" 
                                        hint="contoh: 081876251425"
                                        class=""
                                        counter
                                        maxlength="13"
                                        append-icon="*" />

                                    <v-text-field
                                        v-else-if="(item.buttonType == 'url')"
                                        v-model="item.value"
                                        :label="item.paramName"
                                        hint="https://www.example.com"
                                        :rules="rules.required" 
                                        class=""
                                        append-icon="*" /> -->

                                    <v-text-field
                                        v-if="(item.paramType == 'text' && item.compType != 'button')"
                                        v-model="item.value"
                                        :label="item.paramName"
                                        @blur="preview(item)" 
                                        :rules="rules.required"
                                        class=""
                                        append-icon="*" />

                                    <v-autocomplete v-else-if="item.compType != 'button'"
                                        v-model="selectedInputAttachment" class=""
                                        :loading="isLoadingAttachment"
                                        :items="inputAttachmentList"
                                        :search-input.sync="searchInputAttachment"
                                        :no-filter="true" item-value="_id"
                                        multiple
                                        hide-no-data
                                        @blur="preview(item)"
                                        label="Lampiran"
                                        @keyup.enter="searchAttachment"
                                        :rules="rules.required"
                                        return-object
                                        append-outer-icon="*">
                                        <template v-slot:selection="data">
                                            <span class="mr-2 badge badge-secondary">
                                                {{ data.item.fileName }}
                                            </span>
                                        </template>
                                        <template v-slot:item="data">
                                            <template
                                                v-if="item.paramType != 'image'">
                                                {{ data.item.fileName }} -
                                                ({{ data.item.size > 1000000 ? (data.item.size/1000000).toFixed(1) + " MB" : data.item.size > 1000 ?  (data.item.size/1000).toFixed(1) + ' kB' : data.item.size + " bytes" }})
                                            </template>
                                            <template v-else>
                                                <img :src="data.item.url"
                                                    alt="attachment"
                                                    width="100px">
                                                {{ data.item.fileName }} -
                                                ({{ data.item.size > 1000000 ? (data.item.size/1000000).toFixed(1) + " MB" : data.item.size > 1000 ?  (data.item.size/1000).toFixed(1) + ' kB' : data.item.size + " bytes" }})
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </div>
                                <div>
                                    <v-autocomplete
                                        v-model="selectedInputContactId"
                                        class="" :loading="isLoadingContact"
                                        :items="inputContactList"
                                        :search-input.sync="searchInputContact"
                                        :no-filter="true"
                                        label="Kontak"
                                        hide-no-data
                                        item-value="id"
                                        @keyup.enter="searchContact"
                                        :rules="rules.required"
                                        append-outer-icon="*">
                                        <template v-slot:selection="data">
                                            <span class="ml-2">
                                                {{ data.item.name }}
                                            </span>
                                        </template>
                                        <template v-slot:item="data" >
                                            <div class="d-block" style="width:100%">
                                            ({{ data.item.name }})
                                            {{ data.item.primaryPhoneNumber }}
                                            <span class="pull-right text-right">
                                                <v-icon v-if="data.item.contactStatus == 'ACTIVE'" title="Channel Aktif" color="green" small>mdi-check-circle</v-icon>
                                                <v-icon v-if="data.item.contactStatus == 'INACTIVE'" title="Channel Inaktif" color="red" small>mdi-minus-circle</v-icon>
                                            </span>
                                            </div>
                                        </template>
                                    </v-autocomplete>
                                </div>
                                <div>
                                    <v-autocomplete
                                        v-model="selectedInputChannel"
                                        class="" :loading="isLoadingChannel"
                                        :items="inputChannelList"
                                        item-value="_id" :no-filter="true"
                                        hide-no-data
                                        label="Saluran"
                                        :rules="rules.required"
                                        append-outer-icon="*">
                                        <template v-slot:selection="data">
                                            <span class="ml-2">
                                                {{ data.item.name }}
                                            </span>
                                        </template>
                                        <template v-slot:item="data">
                                            <div class="d-block" style="width:100%">
                                                {{ data.item.name }}
                                                <span class="pull-right text-right">
                                                    <v-icon v-if="data.item.status == 'ACTIVE'" title="Channel Aktif" color="green" small>mdi-check-circle</v-icon>
                                                    <v-icon v-if="data.item.status == 'INACTIVE'" title="Channel Inaktif" color="red" small>mdi-minus-circle</v-icon>
                                                </span>
                                            </div>
                                        </template>
                                    </v-autocomplete>
                                </div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                    @click="closeDialog"
                                    :loading="isLoading">
                                    Kembali
                                </v-btn>
                                <v-btn class="ml-2 btn btn-primary" style="color: white;" v-if="!selectedTemplate.cloud"
                                    :loading="isLoading"
                                    :disabled="!formValid"
                                    @click="tryTemplate">
                                    Kirim
                                </v-btn>
                                <v-btn class="ml-2 btn btn-primary" style="color: white;" v-else
                                    
                                    :loading="isLoading"
                                    :disabled="!formValid"
                                    @click="tryTemplateBusiness">
                                    Kirim
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </template>
            </v-dialog>
        </v-col>
    </v-row>
</template>
<script>
import axios from 'axios'

const uuidv4 = require('uuid/v4');
const template = require('lodash.template');

export default {
    props: ['dialogTestTemplate', 'template'],

    components: {},

    data: function() {
        return {
            broadcast: {
                tempComponents: Object(),
                tempTemplate: String(),
                template: String(),
                header: {
                    type: String(),
                    value: String(),
                },
            },

            selectedInputAttachment: Array(),
            isLoadingAttachment: false,
            inputAttachmentList: [],
            searchInputAttachment: String(),

            selectedInputContactId: String(),
            isLoadingContact: false,
            inputContactList: [],
            searchInputContact: String(),

            selectedInputChannel: String(),
            isLoadingChannel: false,
            inputChannelList: [],
            searchInputChannel: String(),

            selectedTemplate: Object(),

            formValid: false,
            isLoading: false,

            rules: {
                required: [(v) => !!v || ""],
                requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                requiredArray: [(v) => v.length > 0 || ""],
                counter: v => v.length <= 13 || 'maksimum 13 karakter',
            },
        }
    },

    methods: {
        tryTemplateBusiness: async function () {
            this.isLoading = true
            this.broadcastError = Object()

            try {
                const params = this.selectedTemplate.components.map((e, i) => {
                    if(i == 0 && e.compType == 'header' && e.paramType != 'text') {
                        return {
                            code: e.paramCode,
                            values: this.selectedInputAttachment.map(e => e._id)
                        }
                    } else {
                        return {
                            code: e.paramCode,
                            value: e.value
                        }
                    }
                })

                const selectedContact = this.inputContactList.find(e => e.id == this.selectedInputContactId)

                const data = {
                    id: uuidv4(),
                    templateCode: this.selectedTemplate.code,
                    contactId: selectedContact.id,
                    messageType: "broadcast",
                    number: selectedContact.primaryPhoneNumber,
                    channel: "WA",
                    channelId: this.selectedInputChannel,
                    params
                }

                await axios.post(process.env.VUE_APP_API_URL + '/template/send/business', data);

                // this.broadcastDialog = false

                // this.$router.push({
                //     name: 'myconversation',
                //     params: {
                //         id: encodeURIComponent(selectedContact.id)
                //     },
                //     query: {
                //         status: 'open',
                //         sortOrder: 'lastActive',
                //         sortDir: 'desc',
                //         custroles: '',
                //         labels: '',
                //         lastMessagesDirection: '',
                //     }
                // });
            } catch (error) {
                this.broadcastError = this.$helpers.createError(error);
                console.log("test-template", error);
            }
            this.isLoading = false
        },
        tryTemplate: async function () {
            this.isLoading = true
            this.broadcastError = Object()

            try {
                const params = this.selectedTemplate.components.map((e, i) => {
                    if(i == 0 && e.compType == 'header' && e.paramType != 'text') {
                        return {
                            code: e.paramCode,
                            values: this.selectedInputAttachment.map(e => e._id)
                        }
                    } else {
                        return {
                            code: e.paramCode,
                            value: e.value
                        }
                    }
                })

                const selectedContact = this.inputContactList.find(e => e.id == this.selectedInputContactId)

                const data = {
                    id: uuidv4(),
                    templateCode: this.selectedTemplate.code,
                    contactId: selectedContact.id,
                    messageType: "broadcast",
                    number: selectedContact.primaryPhoneNumber,
                    channel: "WA",
                    channelId: this.selectedInputChannel,
                    params
                }

                await axios.post(process.env.VUE_APP_API_URL + '/template/send', data);

                this.broadcastDialog = false

                this.$router.push({
                    name: 'myconversation',
                    params: {
                        id: encodeURIComponent(selectedContact.id)
                    },
                    query: {
                        status: 'open',
                        sortOrder: 'lastActive',
                        sortDir: 'desc',
                        custroles: '',
                        labels: '',
                        lastMessagesDirection: '',
                    }
                });
            } catch (error) {
                this.broadcastError = this.$helpers.createError(error);
                console.log("test-template", error);
            }
            this.isLoading = false
        },
        preview: function (data) {
            if (data.paramType != 'text' || (data.value && data.paramType == 'text')) {
                let item = {
                    ...this.broadcast.tempComponents,
                    [data.paramCode]: data.value
                }
                this.broadcast.tempComponents = item

                if (data.compType == 'header' && data.paramType != 'text') {
                    // const selected = this.inputAttachmentList.find(e => e._id == data.value)
                    // this.broadcast.header.value = selected.url
                    this.broadcast.header.type = data.paramType
                }

                if (data.compType == 'body') {
                    const str = template(this.broadcast.tempTemplate)({
                        ...item
                    })
                    this.selectedTemplate.standardTemplateText = str
                }
            }
        },
        resetForm: function () {
            this.broadcast = {
                tempComponents: Object(),
                tempTemplate: String(),
                template: String(),
                header: {
                    type: String(),
                    value: String(),
                }
            }

            this.selectedInputContactId = String()
            this.isLoadingContact = false
            this.inputContactList = []
            this.searchInputContact = String()

            this.selectedInputAttachment = Array()
            this.isLoadingAttachment = false
            this.inputAttachmentList = []
            this.searchInputAttachment = String()

            this.selectedTemplate = Object()
        },
        searchAttachment: async function () {
            this.isLoadingAttachment = true

            try {
                var {
                    data
                } = await axios.get(process.env.VUE_APP_API_URL + '/attachment', {
                    params: {
                        page: 1,
                        term: this.searchInputAttachment,
                        size: 25,
                        fileType: this.selectedTemplate.components[0].paramType,
                        sort: 'lastUsedOn',
                        sortDir: 'desc'
                    }
                });

                if (this.selectedInputAttachment.length > 0) {
                    const inputAttachmentList =  this.inputAttachmentList.filter(e => this.selectedInputAttachment.includes(e._id))

                    const newDataFilter = data.content.filter(e => {
                        const index = inputAttachmentList.findIndex(el => el == e._id)

                        if (index < 0) {
                            return true
                        } else {
                            return false
                        }
                    })

                    this.inputAttachmentList = [
                        ...inputAttachmentList,
                        ...newDataFilter
                    ]
                } else {
                    this.inputAttachmentList = data.content
                }
            } catch (error) {
                console.log("search-input-attachment", error);
            }
            this.isLoadingAttachment = false
        },
        searchContact: async function () {
            this.isLoadingContact = true
            try {
                var {
                    data
                } = await axios.get(process.env.VUE_APP_API_URL + '/contact/', {
                    params: {
                        page: 1,
                        term: this.searchInputContact,
                        size: 25,
                        status: 'ACTIVE',
                    }
                });

                this.inputContactList = data.content
            } catch (error) {
                console.log("search-input-contact", error);
            }
            this.isLoadingContact = false
        },
        searchChannel: async function () {
            this.isLoadingChannel = true

            try {
                var {
                    data
                } = await axios.get(process.env.VUE_APP_API_URL + '/commchannel/', {
                    params: {
                        page: 1,
                        size: 100
                        // term: this.searchInputChannel,
                    }
                });

                this.inputChannelList = data.content
            } catch (error) {
                console.log("search-input-channel", error);
            }

            this.isLoadingChannel = false
        },
        closeDialog: function() {
            this.$emit('close-dialog', false)
        },
    },

    watch: {
        dialogTestTemplate: function(val) {
            if(val) {
                this.resetForm()
                if (this.inputChannelList.length == 0) this.searchChannel()
                let template = this.template

                let obj = {}

                const temp = template.components.map(e => {
                    obj[e.paramCode] = String();
                    e.value = String()
                    return e
                })

                template.components = temp

                this.broadcast.tempComponents = obj
                this.broadcast.tempTemplate = template.standardTemplateText
                this.selectedTemplate = template
            }
        }
    },
}

</script>
