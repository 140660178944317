<template>
    <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="showDialog"
        persistent
        @keydown.esc="closeDialog"
    >
        <template>
            <v-card>
                <v-toolbar color="deep-purple accent-4" class="text-white text-h6">
                    <span class="text-white"> Test Broadcast</span>
                </v-toolbar>
                <v-form v-model="formValid">
                    <v-card-text>
                        <div class="d-flex justify-content-center mt-2" v-if="isLoadingForm">
                            <b-spinner label="Loading..."></b-spinner>
                        </div>
                        <div v-else>
                            <v-autocomplete
                                v-model="selectedInputContact"
                                :loading="isLoadingContact"
                                :items="inputContactList"
                                :search-input.sync="searchInputContact"
                                :no-filter="true" hide-no-data
                                hide-selected label="Kontak"
                                @keyup.enter="searchContact"
                                :rules="rules.requiredObject">
                                <template v-slot:selection="data">
                                    <span class="ml-2">
                                        {{ data.item.name }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    ({{ data.item.name }})
                                    {{ data.item.primaryPhoneNumber }}
                                </template>
                            </v-autocomplete>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn @click="closeDialog">
                            Kembali
                        </v-btn>
                        <v-btn class="ml-2" style="color: white;"
                            color="deep-purple accent-3"
                            :loading="isLoading"
                            :disabled="!formValid"
                            @click="tryBroadcast">
                            Kirim
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </template>
    </v-dialog>
</template>
<script>
import axios from 'axios'

const uuidv4 = require('uuid/v4');

export default {
    props: ['showDialog', 'broadcastId'],

    components: {},

    data: function() {
        return {
            selectedInputContact: String(),
            searchInputContact: String(),
            inputContactList: Array(),

            selectedInputTemplate: Object(),

            isLoadingContact: false,
            isLoadingForm: false,
            formValid: false,
            isLoading: false,

            rules: {
                required: [(v) => !!v || ""],
                requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                requiredArray: [(v) => v.length > 0 || ""],
            },
        }
    },

    methods: {
        tryBroadcast: async function() {
            try {
                const params = this.selectedInputTemplate.components.map(e => {
                    const res = {
                        code: e.paramCode,
                    }
                    
                    if(e.value) res.value = e.value
                    if(e.values) res.values = e.values

                    return res
                })

                const data = {
                    id: uuidv4(),
                    templateCode: this.selectedInputTemplate.code,
                    contactId: this.selectedInputContact.id,
                    messageType: "broadcast",
                    number: this.selectedInputContact.primaryPhoneNumber,
                    channel: "WA",
                    channelId: this.selectedInputChannel,
                    params
                }

                await axios.post(process.env.VUE_APP_API_URL + '/template/send', data);

                this.closeDialog()

                this.$router.push({
                    name: 'myconversation',
                    params: {
                        id: encodeURIComponent(this.selectedInputContact.id)
                    },
                    query: {
                        status: 'open',
                        sortOrder: 'lastActive',
                        sortDir: 'desc',
                        custroles: '',
                        labels: '',
                        lastMessagesDirection: '',
                    }
                });
            } catch (error) {
                this.broadcastError = this.$helpers.createError(error);
                console.log("test-template", error);
            }
        },
        selectBroadcast: async function(id) {
            this.isLoadingForm = true 

            try {
                const { data } = await axios.get(process.env.VUE_APP_API_URL + '/broadcast/' + id)
                
                var template = await this.getTemplateById(data.messageTemplate.code)
                
                let temp = []

                if(data.params && data.params.length > 0) {
                    temp = template.components.map((e, idx) => {
                        if(data.params[idx].value) e.value = data.params[idx].value
                        if(data.params[idx].values) e.values = data.params[idx].values
                        
                        return e
                    })
                } else {
                    temp = template.components.map(e => {
                        e.value = String()
                        return e
                    })
                }

                template.components = [...temp]
                this.selectedInputTemplate = template
                this.selectedInputChannel = data.channelId
            } catch (error) {
                console.log("Try Broadcast", error);
            }
            this.isLoadingForm = false 
        },
        getTemplateById: async function(code) {
                try {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/template/' + code)

                    return data
                } catch (error) {
                    console.log("search-template");
                }
            },
        searchContact: async function () {
            this.isLoadingContact = true
            try {
                var {
                    data
                } = await axios.get(process.env.VUE_APP_API_URL + '/contact/', {
                    params: {
                        page: 1,
                        term: this.searchInputContact,
                        size: 25,
                        status: 'ACTIVE',
                    }
                });

                this.inputContactList = data.content
            } catch (error) {
                console.log("search-input-contact", error);
            }
            this.isLoadingContact = false
        },
        closeDialog: function() {
            this.$emit('close-dialog', 'test')
        },
    },

    watch: {
        showDialog: async function(val) {
            if(val) {
                this.selectBroadcast(this.broadcastId)
            }
        }
    },
}

</script>
