<template>
    <v-row justify="space-around">
        <v-col cols="auto">
            <v-dialog v-model="show" 
                persistent
                @keydown.esc="closeDialog"
                transition="dialog-bottom-transition" max-width="600"
            >
                <template>
                    <v-card>
                        <v-toolbar color="deep-purple accent-4" class="text-h6 text-white" style="background-color:red">
                            <span class="text-white">Singkronisasi Pesan</span>
                        </v-toolbar>
                        <v-form v-model="formValid">
                            <v-card-text class="">
                                <v-row>
                                    <v-col>
                                        <v-menu ref="date" v-model="isOpenDate" :close-on-content-click="false"
                                            :nudge-right="40" :return-value.sync="selectedDate"
                                            transition="scale-transition" min-width="290px" offset-y>
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    :value="selectedDate ? utcToLocal(selectedDate,'DD-MM-YYYY') : ''"
                                                    :rules="rules.required" class="" label="Tanggal Mulai" readonly
                                                    dense hide-details v-on="on" />
                                            </template>
                                            <v-date-picker v-model="date" no-title scrollable
                                                @input="$refs.date.save(date)" />
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-menu ref="time" v-model="isOpenTime" :close-on-content-click="false"
                                            :nudge-right="40" :return-value.sync="selectedTime"
                                            transition="scale-transition" min-width="290px" offset-y>
                                            <template v-slot:activator="{ on }">
                                                <v-text-field :value="selectedTime" class="" label="Waktu Mulai"
                                                    readonly dense :rules="rules.required" hide-details v-on="on" />
                                            </template>
                                            <v-time-picker v-model="time" @input="$refs.time.save(time)">
                                            </v-time-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                    @click="closeDialog"
                                    :loading="isLoading">
                                    Kembali
                                </v-btn>
                                <v-btn class="ml-2 btn btn-primary" style="color: white;"
                                    
                                    :loading="isLoading"
                                    :disabled="!formValid"
                                    @click="loadMessage">
                                    Load
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </template>
            </v-dialog>
        </v-col>
    </v-row>
</template>
<script>
import axios from 'axios'

var moment = require('moment');

export default {
    props: ['show', 'commChannel'],

    components: {},

    data: function() {
        return {
            isOpenDate: false,
            selectedDate: String(),
            date: String(),
            isOpenTime: false,
            selectedTime: String(),
            time: String(),

            formValid: false,
            isLoading: false,

            rules: {
                required: [(v) => !!v || ""],
                requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                requiredArray: [(v) => v.length > 0 || ""],
                counter: v => v.length <= 13 || 'maksimum 13 karakter',
            },
        }
    },

    methods: {
        loadMessage: async function() {
            const date = new Date(this.selectedDate + " " + this.selectedTime + ":00")

            try {
                const body = {
                    timestamp: date,
                    webhook_token: this.commChannel.waboxToken,
                    webhook_id: this.commChannel._id,
                    webhook_url: this.commChannel.webhook,
                    wa_url: this.commChannel.apiUrl
                }

                await axios.post(process.env.VUE_APP_API_URL + '/message/load', body);

                this.closeDialog()
            } catch (error) {
                console.log("sync-message", error);
            }
        },
        resetForm: function () {
            this.isOpenDate = false
            this.selectedDate = String()
            this.date = String()
            this.isOpenTime = false
            this.selectedTime = String()
            this.time = String()
        },
        closeDialog: function() {
            this.$emit('close-dialog', false)
        },
        utcToLocal: function(date, format) {
            var utcLocal = moment(date).local().format(format);
            return utcLocal;
        },
        utcToLocalView: function(date, format) {
            var utcLocal = moment(date).format(format);
            return utcLocal;
        },
        localToUtc: function(date, format) {
            var utcLocal = moment(date).utc().format(format);
            return utcLocal;
        },
    },

    watch: {
        show: function(val) {
            if(val) {
                this.resetForm()
            }
        }
    },
}

</script>
